@import '@yaireo/tagify/dist/tagify.css';

:root {
  --carrot-orange: #f88d2b;
  --blue: #0097ce;
  --dark-blue: #182642;
  --green: #6cca98;
  --purple: #8b85ca;
  --yellow: #ffb71b;
  --gray: #e9e8ed;
  --light-gray: #fafafa;
  --orange: #d87e2a;
  --dark-gray: #9a9a9a;
  --subTitle-font: 'Gotham Book', sans-serif;
  --Title-fontSize: 28px;
  --blue-gray: #f4f6fd;
  --black: #373737;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Normal', 'Jost', 'Helvetica Neue', 'Segoe UI',
    sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #333;
}

.Select {
  position: relative;
}

.Select input::-webkit-contacts-auto-fill-button,
.Select input::-webkit-credentials-auto-fill-button {
  display: none !important;
}

.Select input::-ms-clear,
.Select input::-ms-reveal {
  display: none !important;
}

.Select,
.Select div,
.Select input,
.Select span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ant-select-selector {
  border-radius: 4px !important;
}

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
  opacity: 0.35;
}

.Select.is-disabled > .Select-control {
  background-color: #f9f9f9;
}

.Select.is-disabled > .Select-control:hover {
  box-shadow: none;
}

.Select.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
}

.Select.is-open > .Select-control .Select-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Select.is-searchable.is-focused:not(.is-open) > .Select-control,
.Select.is-searchable.is-open > .Select-control {
  cursor: text;
}

.Select.is-focused > .Select-control {
  background: #fff;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  background: #fff;
}

.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
  padding-right: 42px;
}

.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label,
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  color: #333;
}

.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label,
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label {
  cursor: pointer;
  text-decoration: none;
}

.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:focus,
.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:hover,
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:focus,
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:hover {
  color: #007eff;
  outline: none;
  text-decoration: underline;
}

.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:focus,
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:focus {
  background: #fff;
}

.Select.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

.Select.is-open .Select-arrow,
.Select .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666;
}

.ant-select-item-option {
  &:hover {
    background-color: var(--blue) !important;
  }
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.ant-select-item-option-active {
  background-color: var(--blue) !important;
}

.Select.Select--rtl {
  direction: rtl;
  text-align: right;
}

.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Select-control .Select-input:focus {
  outline: none;
  background: #fff;
}

.Select--single > .Select-control .Select-value,
.Select-placeholder {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select-input {
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}

.Select-input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 17px;
  padding: 8px 0 12px;
  -webkit-appearance: none;
}

.is-focused .Select-input > input {
  cursor: text;
}

.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  text-align: center;
}

.Select-loading,
.Select-loading-zone {
  position: relative;
  vertical-align: middle;
  width: 16px;
}

.Select-loading {
  -webkit-animation: Select-animation-spin 0.4s infinite linear;
  -o-animation: Select-animation-spin 0.4s infinite linear;
  animation: Select-animation-spin 0.4s infinite linear;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-right-color: #333;
  display: inline-block;
}

.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 0.2s;
  -o-animation: Select-animation-fadeIn 0.2s;
  animation: Select-animation-fadeIn 0.2s;
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px;
}

.Select-clear-zone:hover {
  color: #d0021b;
}

.Select-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
}

.Select--multi .Select-clear-zone {
  width: 17px;
}

.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px;
}

.Select--rtl .Select-arrow-zone {
  padding-right: 0;
  padding-left: 5px;
}

.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}

.Select-control > :last-child {
  padding-right: 5px;
}

.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}

.Select .Select-aria-only {
  position: absolute;
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  float: left;
}

@-webkit-keyframes Select-animation-fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes Select-animation-fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}

.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}

.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #666;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Select-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.Select-option.is-selected {
  background-color: #f5faff;
  background-color: rgba(0, 126, 255, 0.04);
  color: #333;
}

.Select-option.is-focused {
  background-color: #ebf5ff;
  background-color: rgba(0, 126, 255, 0.08);
  color: #333;
}

.Select-option.is-disabled {
  color: #ccc;
  cursor: default;
}

.Select-noresults {
  box-sizing: border-box;
  color: #999;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0;
}

.Select--multi.Select--rtl .Select-input {
  margin-left: 0;
  margin-right: 10px;
}

.Select--multi.has-value .Select-input {
  margin-left: 5px;
}

.Select--multi .Select-value {
  background-color: #ebf5ff;
  background-color: rgba(0, 126, 255, 0.08);
  border-radius: 2px;
  border: 1px solid #c2e0ff;
  border: 1px solid rgba(0, 126, 255, 0.24);
  color: #007eff;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
}

.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle;
}

.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px;
}

.Select--multi a.Select-value-label {
  color: #007eff;
  cursor: pointer;
  text-decoration: none;
}

.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c2e0ff;
  border-right: 1px solid rgba(0, 126, 255, 0.24);
  padding: 1px 5px 3px;
}

.Select--multi .Select-value-icon:focus,
.Select--multi .Select-value-icon:hover {
  background-color: #d8eafd;
  background-color: rgba(0, 113, 230, 0.08);
  color: #0071e6;
}

.Select--multi .Select-value-icon:active {
  background-color: #c2e0ff;
  background-color: rgba(0, 126, 255, 0.24);
}

.Select--multi.Select--rtl .Select-value {
  margin-left: 0;
  margin-right: 5px;
}

.Select--multi.Select--rtl .Select-value-icon {
  border-right: none;
  border-left: 1px solid #c2e0ff;
  border-left: 1px solid rgba(0, 126, 255, 0.24);
}

.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
  color: #333;
}

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3;
}

.Select--multi.is-disabled .Select-value-icon:active,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:hover {
  background-color: #fcfcfc;
}

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}

a {
  color: #007eff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  padding: 0 30px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--carrot-orange);
  font-family: 'Gotham Bold', sans-serif;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.66em;
  margin-top: 0;
}

.h1,
h1 {
  font-size: 3em;
}

.h2,
h2 {
  font-size: 2em;
  font-weight: 300;
}

.h3,
h3 {
  font-size: 1.25em;
}

.h4,
h4 {
  font-size: 1em;
}

.h5,
h5 {
  font-size: 0.85em;
}

.h6,
h6 {
  font-size: 0.75em;
}

.page-body,
.page-footer,
.page-header {
  padding: 30px 0;
}

.page-header {
  background-color: #007eff;
  color: #bfdfff;
}

.page-header h1,
.page-header h2,
.page-header h3 {
  color: #fff;
}

.page-header p {
  font-size: 1.2em;
  margin: 0;
}

.page-header a {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
  color: #fff;
  text-decoration: none;
}

.page-header a:focus,
.page-header a:hover {
  border-bottom-color: #fff;
  outline: none;
  text-decoration: none;
}

button {
  border-radius: 4px;
}

.page-subheader {
  background-color: #e6f2ff;
  line-height: 20px;
  padding: 30px 0;
}

.page-subheader__button {
  float: right;
}

.page-subheader__link {
  border-bottom: 1px solid rgba(0, 126, 255, 0.3);
  outline: none;
  text-decoration: none;
}

.page-subheader__link:focus,
.page-subheader__link:hover {
  border-bottom-color: #007eff;
  outline: none;
  text-decoration: none;
}

.page-footer {
  background-color: #fafafa;
  color: #999;
  padding: 30px 0;
  text-align: center;
}

.page-footer a {
  color: #000;
}

@media (min-width: 480px) {
  .page-body,
  .page-header {
    padding: 60px 0;
  }

  .page-header {
    font-size: 1.4em;
  }

  .page-subheader {
    font-size: 1.125em;
    line-height: 28px;
  }
}

.checkbox-list {
  margin-top: 0.5em;
  overflow: hidden;
}

.checkbox-list > .checkbox {
  clear: left;
  float: left;
  margin-top: 0.5em;
}

.checkbox-control {
  margin-right: 0.5em;
  position: relative;
  top: -1px;
}

.switcher {
  color: #999;
  cursor: default;
  font-size: 12px;
  margin: 10px 0;
  text-transform: uppercase;
}

.switcher .link {
  color: #007eff;
  cursor: pointer;
  font-weight: 500;
  margin-left: 10px;
}

.switcher .link:hover {
  text-decoration: underline;
}

.switcher .active {
  color: #666;
  font-weight: 500;
  margin-left: 10px;
}

.section {
  margin-bottom: 40px;
}

.hint {
  font-size: 0.85em;
  margin: 15px 0;
  color: #666;
}

.virtual-scroll {
  z-index: 1;
}

/* -------------------------------------- My custom CSS -------------------------------------------*/
.react-select__container {
  width: 200px;
  margin-left: 20px;
}

.react-select__control {
  min-height: 0 !important;
  height: 30px;
  border-radius: 2px !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  border-radius: 0 !important;
  width: 98%;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #ff5722 !important;
}

.ant-menu-submenu-active {
  color: #ff5722 !important;
}

.ant-menu-item {
  border-bottom: 4px solid transparent !important;
}

.ant-menu-item-selected {
  color: white !important;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  /* padding: 0 16px !important; */
}

/* .ant-popover-inner-content {
	padding: 0 !important;
} */

.ant-popover-arrow {
  display: none;
}

#sk-holder #sk-container {
  z-index: 9 !important;
}

#sk-holder #sk-messenger-button {
  z-index: 9 !important;
}

.StateHistoryDropdown .ant-select-dropdown-menu-item-selected {
  background-color: white !important;
}

.StateHistoryDropdown .ant-select-dropdown-menu-item:hover {
  background-color: transparent !important;
}

.StateHistoryDropdown .ant-select-dropdown-menu-item {
  background-color: transparent !important;
  padding: 0px;
}

.anticon {
  vertical-align: text-bottom !important;
}

/* Override antd */
.ant-btn-primary,
.ant-btn-default {
  background-color: #fff;
  border-color: var(--carrot-orange);
  color: var(--carrot-orange);
}

.ant-btn-primary:disabled:hover {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-color: rgba(0, 0, 0, 0.04) !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff !important;
  background-color: var(--carrot-orange) !important;
  border-color: var(--carrot-orange) !important;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
  color: var(--carrot-orange);
  border-color: var(--carrot-orange);
}

.ant-btn.ant-btn-dangerous:focus {
  background-color: #fff;
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-btn.ant-btn-dangerous:hover {
  background-color: #ff4d4f;
  color: #fff;
  border-color: #ff4d4f;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}

.ant-btn-secondary {
  background-color: var(--carrot-orange);
  border-color: var(--carrot-orange);
  color: var(--dark-blue);
}

.ant-btn-secondary:hover,
.ant-btn-secondary:active,
.ant-btn-secondary:focus {
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
  color: var(--carrot-orange);
}

.ant-btn-default:hover,
.ant-btn-default:active {
  background-color: var(--carrot-orange) !important;
  border-color: var(--carrot-orange) !important;
  color: #fff !important;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-switch {
  border-color: var(--blue) !important;
}

.QIwrM .ant-switch-handle::before {
  background-color: var(--blue) !important;
}

.primary-color {
  color: #ff5722;
}

.primary-bg {
  background-color: #ff5722;
}

.primary-border-color {
  border-color: #ff5722;
}

/* use official Joboti colors */

.blue-color {
  color: var(--blue);
}

.blue-bg {
  background-color: var(--blue);
}

.blue-border-color {
  border-color: var(--blue);
}

.intent-list-drawer {
  padding: 0;
}

/* Bouncy Ball Loader */
/* Bouncy Ball */
.bouncy_ball_wrap {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bouncy_ball_text {
  color: #ee532f;
  display: inline-block;
  margin-left: 5px;
}
.bouncy_ball {
  position: relative;
  display: inline-block;
  height: 1.6rem;
  width: 100%;
  left: 50%;
}
.bouncy_ball:before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ee532f;
  transform-origin: 50%;
  animation: bounce 500ms alternate infinite ease;
}
@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

@keyframes flyRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      300px
    ); /* You can adjust this value based on the desired distance */
  }
}

@keyframes shake {
  0% {
    transform: translateX(-3px);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(3px);
  }
}

.shaking {
  animation: shake 0.2s linear 5; /* Shake back and forth 5 times quickly over 1 second */
}

.flying {
  animation: flyRight 1s forwards; /* 1 second duration, and 'forwards' means the icon will stay in the final position after the animation completes */
}

.ant-table-filter-dropdown-btns > .ant-btn {
  border-radius: 4px !important;
  &:hover {
    background-color: var(--carrot-orange) !important;
    color: white !important;
  }
}

:where(.css-dev-only-do-not-override-h1j5xx).ant-btn-link {
  color: var(--carrot-orange);
}
